import AWS from "aws-sdk";
import decrypt from "services/utils/decrypt";

const decryptCredentials = async () => {
  const decryptedAccessKeyId = await decrypt(process.env.REACT_APP_ENCRYPTED_AWS_ACCESS_KEY_ID);

  const decryptedSecretAccessKey = await decrypt(
    process.env.REACT_APP_ENCRYPTED_AWS_SECRET_ACCESS_KEY
  );

  return {
    accessKeyId: decryptedAccessKeyId,
    secretAccessKey: decryptedSecretAccessKey,
  };
};

const initializeS3 = async () => {
  const { accessKeyId, secretAccessKey } = await decryptCredentials();

  const s3 = new AWS.S3({
    accessKeyId,
    secretAccessKey,
    region: "ap-south-1",
  });

  return s3;
};

const DefaultParams = {
  ACL: "public-read",
  Body: ``,
  Bucket: process.env.REACT_APP_S3_BUCKET,
  ContentType: `image/jpeg`,
  ContentEncoding: "base64",
  Key: ``,
};

const upload = async (params) => {
  const s3 = await initializeS3();

  return s3
    .upload(params)
    .promise()
    .then((data) => data)
    .catch((err) => {
      throw err;
    });
};

const uploadToS3 = async (requestBody) => {
  const params = {
    ...DefaultParams,
    ...requestBody,
  };

  try {
    const response = await upload(params);
    if (response) {
      const url = `https://s3.dualstack.ap-south-1.amazonaws.com/${response.Bucket}/${response.key}`;
      return url;
    }
  } catch (err) {
    alert(err);

    throw err;
  }
};

export default uploadToS3;

